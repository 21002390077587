import React from 'react'
import { Layout, SEO } from 'components/common'
import Contact from 'components/contact'

export default () => (
  <Layout>
    <SEO />
    <Contact />
  </Layout>
)

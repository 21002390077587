import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 10rem 0;

  @media (max-width: 960px) {
    padding: 2rem 0;
  }

  p {
    line-height: 1.5;
    margin-bottom: 0.5rem;

    strong {
      font-size: 14pt;
      border-bottom: 3px solid;
      border-image-source: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
      border-image-slice: 1;
      margin: 0 0.2rem;
    }
  }
`

export const Details = styled.div`
  @media (max-width: 960px) {
    padding-right: unset;
    width: 100%;
    order: 1;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: #212121;
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: #707070;
  }
`

export const Thumbnail = styled.div`
  width: 100%;
  max-width: 48%;

  @media (max-width: 960px) {
    max-width: 100%;

    &:last-child {
      display: none;
    }
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

export const Item = styled.div`
  width: 100%;
  max-width: 48%;

  @media (max-width: 960px) {
    max-width: 100%;

    &:last-child {
      display: none;
    }
  }
`

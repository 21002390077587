import React from 'react'
import { Container } from 'components/common'
import contact from 'assets/contact.svg'
import { Flex, Item, Wrapper } from './styles'
import ContactForm from './ContactForm'
import { Link } from 'gatsby'
import { FlexHeader } from '../landing/Intro/styles'

export default () => (
  <Wrapper as={Container} id="contact">
    <Flex>
      <Item>
        <FlexHeader>
          <Link to="/">
            <h1>Dev Workshops</h1>
          </Link>
        </FlexHeader>
        <h2>Get in touch</h2>
        <ContactForm />
      </Item>
      <Item>
        <img src={contact} alt="Contact" />
      </Item>
    </Flex>
  </Wrapper>
)
